import { NgModule } from "@angular/core";
import { S25HomeSearchComponent } from "./s25.home.search.component";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { CommonModule } from "@angular/common";
import { S25qlSearchInputComponent } from "./s25ql.search.input.component";
import { FormsModule } from "@angular/forms";
import { S25HelpModule } from "../s25-help/s25.help.module";
import { S25qlSuggestAddressComponent } from "./s25ql.suggest.address.component";
import { S25qlSearchInputSuggestionsComponent } from "./s25ql.search.input.suggestions.component";
import { S25SecurityLinkModule } from "../s25-security-link/s25.security.link.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25QLSearchAdvancedInputComponent } from "./s25ql.search.advanced.input.component";
import { S25qlSearchAdvancedCriteriaComponent } from "./s25ql.search.advanced.criteria.component";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25qlSearchAdvancedCriterionComponent } from "./s25ql.search.advanced.criterion.component";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25RadioModule } from "../s25-radio/s25.radio.module";
import { S25qlSearchAdvancedCustomAttributeComponent } from "./s25ql.search.advanced.custom.attribute.component";
import { S25qlSearchAdvancedInputTaskComponent } from "./s25ql.search.advanced.input.task.component";
import { S25qlSearchSimpleInputComponent } from "./s25ql.search.simple.input.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25ObjectModule } from "../s25-object/s25.object.module";
import { S25ModalShareSearchComponent } from "./s25.modal.share.search.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25CaptureEventModule } from "../s25-capture-event/s25.capture.event.module";
import { S25PublisherModule } from "../s25-publisher/s25.publisher.module";
import { S25InfoMessageModule } from "../s25-info-message/s25.info.message.module";
import { S25SearchResultsListComponent } from "./s25.search.results.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25DatePatternModule } from "../s25-datepattern/s25.datepattern.module";
import { S25QLSearchAdvancedProfileComponent } from "./s25ql.search.advanced.profile.component";
import { S25StandardScheduleModule } from "../s25-standard-schedule/s25.standard.schedule.module";

@NgModule({
    imports: [
        S25DropdownPaginatedModule,
        S25IconModule,
        CommonModule,
        FormsModule,
        S25HelpModule,
        S25SecurityLinkModule,
        S25ToggleButtonModule,
        S25SimpleCollapseModule,
        S25DatepickerModule,
        S25EditableModule,
        S25CheckboxModule,
        S25MultiselectModule,
        S25TimepickerModule,
        S25RadioModule,
        S25ModalModule,
        S25ObjectModule,
        S25LoadingInlineModule,
        S25CaptureEventModule,
        S25PublisherModule,
        S25InfoMessageModule,
        S25TableModule,
        S25OptModule,
        S25DatePatternModule,
        S25StandardScheduleModule,
    ],
    declarations: [
        S25HomeSearchComponent,
        S25qlSearchInputComponent,
        S25qlSuggestAddressComponent,
        S25qlSearchInputSuggestionsComponent,
        S25QLSearchAdvancedInputComponent,
        S25qlSearchAdvancedCriteriaComponent,
        S25qlSearchAdvancedCriterionComponent,
        S25qlSearchAdvancedCustomAttributeComponent,
        S25qlSearchAdvancedInputTaskComponent,
        S25qlSearchSimpleInputComponent,
        S25ModalShareSearchComponent,
        S25SearchResultsListComponent,
        S25QLSearchAdvancedProfileComponent,
    ],
    exports: [S25HomeSearchComponent, S25SearchResultsListComponent],
    providers: [
        S25HomeSearchComponent,
        S25qlSearchInputComponent,
        S25qlSuggestAddressComponent,
        S25qlSearchInputSuggestionsComponent,
        S25QLSearchAdvancedInputComponent,
        S25qlSearchAdvancedCriteriaComponent,
        S25qlSearchAdvancedCriterionComponent,
        S25qlSearchAdvancedCustomAttributeComponent,
        S25qlSearchAdvancedInputTaskComponent,
        S25qlSearchSimpleInputComponent,
        S25ModalShareSearchComponent,
        S25SearchResultsListComponent,
        S25QLSearchAdvancedProfileComponent,
    ],
})
export class S25QLModule {}
